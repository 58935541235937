import { put, takeEvery, call, select, type SagaReturnType } from 'redux-saga/effects'
import * as api from 'src/api'
import { showNotification } from 'src/components/parts/notifications/notifications'
import { fetchBulkListVehicles, updatedVehiclesRemovedFromRepairs } from 'src/redux/scooterBulk'
import { selectCurrentRepairVehicle } from 'src/redux/repair/repair.selectors'
import {
    GET_REPAIR_VEHICLE,
    CREATE_VEHICLE_SPECS,
    REMOVE_VEHICLES_FROM_REPAIR,
    GET_REPAIR_ACCOUNT,
    CREATE_REPAIR_ISSUE,
    UPDATE_REPAIR_ISSUE,
    CREATE_REPAIR_ACCOUNT,
    UPDATE_VEHICLE_SPECS,
    SEARCH_REPAIR_ACCOUNT,
    DELETE_REPAIR_ACCOUNT,
    DELETE_VEHICLE_IMG,
    DELETE_REPAIR_ISSUE,
    GET_REPAIR_ISSUES,
    type CreateRepairAccount,
    type GetRepairAccount,
    type SearchRepairAccount,
    type DeleteRepairAccount,
    type GetRepairVehicle,
    type CreateVehicleSpecs,
    type UpdateVehicleSpecs,
    type DeleteVehicleImg,
    type CreateRepairIssue,
    type UpdateRepairIssue,
    type DeleteRepairIssue,
    type RemoveFromRepairs,
    type GetRepairIssues,
} from 'src/redux/repair/repair.types'
import {
    setCurrentRepairAccount,
    setCurrentRepairVehicle,
    setRepairIssues,
    setSpareParts,
    getRepairVehicle,
} from 'src/redux/repair/repair.actions'
import { selectVehicleModels } from 'src/redux/vehicleModel'
import { type VehicleModel } from 'src/api/fm/vehicles/vehicles.model'
import { type SparePart } from 'src/api/fm/warehouseInventory/warehouseInventory.model'
import * as routes from 'src/constants/routes'

type CreateMechanicRes = SagaReturnType<typeof api.createMechanic>

export function* createRepairAccountSaga(action: CreateRepairAccount) {
    const email = action.payload
    const res: CreateMechanicRes = yield call(api.createMechanic, email)

    if (res instanceof Error) {
        yield call(showNotification, 'error', res.message)
    } else {
        yield call(
            showNotification,
            'success',
            'Successfully created repair account. An email to set up the password has been sent to the user.',
        )
        yield put(setCurrentRepairAccount(res))
    }
}

type GetMechanicByIdRes = SagaReturnType<typeof api.getMechanicById>

export function* getRepairAccountSaga(action: GetRepairAccount) {
    const id = action.payload
    const res: GetMechanicByIdRes = yield call(api.getMechanicById, id)

    if (res instanceof Error) {
        yield call(showNotification, 'error', res.message)
        // @ts-expect-error fix this
        yield put(setCurrentRepairAccount({ error: 'Account not found' }))
    } else {
        yield put(setCurrentRepairAccount(res))
    }
}

type GetMechanicByEmailRes = SagaReturnType<typeof api.getMechanicByEmail>

export function* searchRepairAccountSaga(action: SearchRepairAccount) {
    const { searchTerm, navigateFn } = action.payload
    const res: GetMechanicByEmailRes | GetMechanicByIdRes = searchTerm.includes('@')
        ? yield call(api.getMechanicByEmail, searchTerm)
        : yield call(api.getMechanicById, searchTerm)

    if (res instanceof Error) {
        yield call(showNotification, 'error', res.message)
    } else {
        // @ts-expect-error https://github.com/redux-saga/redux-saga/issues/1943
        yield call(navigateFn, `${routes.REPAIR_MANAGEMENT}/${res.id}`)
    }
}

type DeleteMechanicRes = SagaReturnType<typeof api.deleteMechanic>

export function* deleteRepairAccountSaga(action: DeleteRepairAccount) {
    try {
        const { id, navigateFn } = action.payload
        const result: DeleteMechanicRes = yield call(api.deleteMechanic, id)

        if (result instanceof Error) {
            yield call(showNotification, 'error', result.message)
        } else {
            // @ts-expect-error https://github.com/redux-saga/redux-saga/issues/1943
            yield call(navigateFn, routes.REPAIR_MANAGEMENT)
            yield call(showNotification, 'success', 'Successfully deleted repair account')
        }
    } catch (e) {
        yield call(showNotification, 'error', (e as Error).message)
    }
}

type GetRepairVehicleRepairIssuesRes = SagaReturnType<typeof api.getRepairIssues>

export function* getRepairVehicleSaga(action: GetRepairVehicle) {
    const vehicleModels: VehicleModel[] = yield select(selectVehicleModels)
    const vehicleModel = vehicleModels.find(vm => vm.id === action.payload) ?? null
    const spareParts: SparePart[] = yield call(api.getVehicleModelSpareParts, action.payload)
    const repairIssues: GetRepairVehicleRepairIssuesRes = yield call(api.getRepairIssues, action.payload)

    if (spareParts instanceof Error) {
        yield call(showNotification, 'error', spareParts.message)
    } else if (repairIssues instanceof Error) {
        yield call(showNotification, 'error', repairIssues.message)
    } else {
        yield put(setCurrentRepairVehicle(vehicleModel))
        yield put(setSpareParts(spareParts))
        yield put(setRepairIssues(repairIssues))
    }
}

type GetRepairIssuesRes = SagaReturnType<typeof api.getRepairIssues>

function* getRepairIssuesSaga(action: GetRepairIssues) {
    const repairIssues: GetRepairIssuesRes = yield call(api.getRepairIssues, action.payload)

    if (repairIssues instanceof Error) {
        yield call(showNotification, 'error', repairIssues.message)
    } else {
        yield put(setRepairIssues(repairIssues))
    }
}

type CreateVehicleModelRes = SagaReturnType<typeof api.createVehicleModel>

export function* createVehicleModel(action: CreateVehicleSpecs) {
    const vehicleModel = action.payload
    const res: CreateVehicleModelRes = yield call(api.createVehicleModel, vehicleModel)

    if (res instanceof Error) {
        yield call(showNotification, 'error', res.message)
    } else {
        yield call(showNotification, 'success', 'Successfully created vehicle')
        yield put(setSpareParts([]))
        yield put(setCurrentRepairVehicle(res))
    }
}

type UpdateVehicleModelRes = SagaReturnType<typeof api.updateVehicleModel>

export function* updateVehicleModel(action: UpdateVehicleSpecs) {
    const vehicleModel = action.payload
    const res: UpdateVehicleModelRes = yield call(api.updateVehicleModel, vehicleModel)

    if (res instanceof Error) {
        yield call(showNotification, 'error', res.message)
    } else {
        yield call(showNotification, 'success', 'Successfully updated vehicle')
        yield put(setCurrentRepairVehicle(res))
    }
}

type DeleteVehicleModelImageRes = SagaReturnType<typeof api.deleteVehicleModelImage>

export function* deleteVehicleModelImageSaga(action: DeleteVehicleImg) {
    const vehicleModel = action.payload
    const result: DeleteVehicleModelImageRes = yield call(api.deleteVehicleModelImage, vehicleModel.id)

    if (result instanceof Error) {
        yield call(showNotification, 'error', result.message)
    } else {
        yield call(showNotification, 'success', 'Successfully deleted vehicle image')
        yield put(setCurrentRepairVehicle({ ...vehicleModel, picture: '' }))
    }
}

type CreateRepairIssueRes = SagaReturnType<typeof api.createRepairIssue>

export function* createNewRepairIssue(action: CreateRepairIssue) {
    const vehicle: VehicleModel = yield select(selectCurrentRepairVehicle)
    const result: CreateRepairIssueRes = yield call(api.createRepairIssue, vehicle.id, action.payload)

    if (result instanceof Error) {
        yield call(showNotification, 'error', result.message)
    } else {
        yield call(showNotification, 'success', 'Successfully created repair issue')
        yield put(getRepairVehicle(vehicle.id))
    }
}

type UpdateRepairIssueRes = SagaReturnType<typeof api.updateRepairIssue>

export function* updateExistingRepairIssue(action: UpdateRepairIssue) {
    const repairIssue = action.payload
    const result: UpdateRepairIssueRes = yield call(api.updateRepairIssue, repairIssue)

    if (result instanceof Error) {
        yield call(showNotification, 'error', result.message)
    } else {
        yield call(showNotification, 'success', 'Successfully updated repair issue')
        yield put(getRepairVehicle(repairIssue.modelId))
    }
}

type DeleteRepairIssueRes = SagaReturnType<typeof api.deleteRepairIssue>

export function* deleteRepairIssueSaga(action: DeleteRepairIssue) {
    const { vehicleModelId, repairIssueId } = action.payload
    const result: DeleteRepairIssueRes = yield call(api.deleteRepairIssue, vehicleModelId, repairIssueId)

    if (result instanceof Error) {
        yield call(showNotification, 'error', result.message)
    } else {
        yield call(showNotification, 'success', 'Successfully deleted repair issue')
        yield put(getRepairVehicle(vehicleModelId))
    }
}

type RemoveFromRepairRes = SagaReturnType<typeof api.removeFromRepair>

export function* removeFromRepairs(action: RemoveFromRepairs) {
    try {
        const { scooterIds } = action.payload
        const res: RemoveFromRepairRes = yield call(api.removeFromRepair, scooterIds)

        if (res instanceof Error) {
            throw res
        } else {
            const { vehicleIDs, errors } = res.data
            yield put(updatedVehiclesRemovedFromRepairs(vehicleIDs))

            if (!errors) {
                const successMsg =
                    scooterIds.length === 1
                        ? 'Successfully removed vehicle from repairs'
                        : 'Successfully removed vehicles from repairs'

                yield call(showNotification, 'success', successMsg)
            } else {
                const errorMsg =
                    errors.length === scooterIds.length
                        ? 'Failed to remove vehicles from repairs'
                        : 'Failed to remove some vehicles from repairs'

                yield call(showNotification, 'error', errorMsg)
            }
            yield put(fetchBulkListVehicles(scooterIds))
        }
    } catch (e) {
        yield call(showNotification, 'error', (e as Error).message)
    }
}

export default function* watcher() {
    yield takeEvery(CREATE_REPAIR_ACCOUNT, createRepairAccountSaga)
    yield takeEvery(DELETE_REPAIR_ACCOUNT, deleteRepairAccountSaga)
    yield takeEvery(GET_REPAIR_ACCOUNT, getRepairAccountSaga)
    yield takeEvery(SEARCH_REPAIR_ACCOUNT, searchRepairAccountSaga)
    yield takeEvery(GET_REPAIR_VEHICLE, getRepairVehicleSaga)
    yield takeEvery(GET_REPAIR_ISSUES, getRepairIssuesSaga)
    yield takeEvery(CREATE_VEHICLE_SPECS, createVehicleModel)
    yield takeEvery(UPDATE_VEHICLE_SPECS, updateVehicleModel)
    yield takeEvery(DELETE_VEHICLE_IMG, deleteVehicleModelImageSaga)
    yield takeEvery(CREATE_REPAIR_ISSUE, createNewRepairIssue)
    yield takeEvery(UPDATE_REPAIR_ISSUE, updateExistingRepairIssue)
    yield takeEvery(DELETE_REPAIR_ISSUE, deleteRepairIssueSaga)
    yield takeEvery(REMOVE_VEHICLES_FROM_REPAIR, removeFromRepairs)
}
