import { call, put, takeEvery } from 'redux-saga/effects'
import { getNextPhotoToLabel, labelPhoto } from 'src/api/fm/photoLabels/photoLabels'
import { showNotification } from 'src/components/parts/notifications/notifications'
import { getNextPhotoToLabelAction, setNextPhotoToLabelAction } from 'src/redux/photoLabels/photoLabels.actions'
import {
    GET_NEXT_PHOTO_TO_LABEL,
    type LabelPhotoAction,
    LABEL_PHOTO,
    type PhotoToLabel,
    type GetNextPhotoToLabelAction,
} from 'src/redux/photoLabels/photoLabels.types'

function* labelPhotoSaga(action: LabelPhotoAction) {
    const { photoRef, labels } = action.payload
    const res: string | Error = yield call(labelPhoto, photoRef, labels)

    if (res instanceof Error) {
        yield call(showNotification, 'error', res.message)
        return
    }

    yield call(showNotification, 'success', 'Label photo success')
    yield put(getNextPhotoToLabelAction())
}

function* getNextPhotoToLabelSaga(_: GetNextPhotoToLabelAction) {
    const res: PhotoToLabel | Error = yield call(getNextPhotoToLabel)

    if (res instanceof Error) {
        if (res.message === 'No photo to review') {
            yield put(setNextPhotoToLabelAction(null))
        }

        return
    }

    yield put(setNextPhotoToLabelAction(res))
}

export default function* watcher() {
    yield takeEvery(LABEL_PHOTO, labelPhotoSaga)
    yield takeEvery(GET_NEXT_PHOTO_TO_LABEL, getNextPhotoToLabelSaga)
}
