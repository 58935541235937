import { call, put, type SagaReturnType, takeEvery } from 'redux-saga/effects'
import { receiptsGetBulkUrl } from 'src/api'
import { showNotification } from 'src/components/parts/notifications/notifications'
import { GET_RECEIPTS_BULK_URL, type GetReceiptsBulkUrl } from 'src/redux/receipts/receipts.types'
import { setReceiptsBulkUrl } from 'src/redux/receipts/receipts.actions'

type ReceiptsGetBulkUrlRes = SagaReturnType<typeof receiptsGetBulkUrl>

function* fetchReceiptsBulkUrl(action: GetReceiptsBulkUrl) {
    const params = action.payload
    const response: ReceiptsGetBulkUrlRes = yield call(receiptsGetBulkUrl, params)

    if (response instanceof Error) {
        yield call(showNotification, 'error', response.message)
        return
    }

    yield call(showNotification, 'success', 'Successfully created receipts URL')
    yield put(setReceiptsBulkUrl(response))
}

export default function* watcher() {
    yield takeEvery(GET_RECEIPTS_BULK_URL, fetchReceiptsBulkUrl)
}
