import { put, takeEvery, call, type SagaReturnType } from 'redux-saga/effects'
import { showNotification } from 'src/components/parts/notifications/notifications'
import {
    getUserParkingHistory,
    getUserStats,
    getSafetyControlSystemData,
    persistUserReview,
} from 'src/api/fm/safetyControlSystem/safetyControlSystem'
import {
    setSafetyControlUserStats,
    setSafetyControlUserParkingHistory,
    setSafetyControlSystemInfo,
    getSafetyControlInfo,
    markNoMoreUsers,
    clearSafetyControlInfo,
} from 'src/redux/safetyControlSystem/safetyControlSystem.actions'
import {
    SAFETY_CONTROL_SYSTEM_INFO,
    SAFETY_CONTROL_SYSTEM_DATA,
    SEND_ACTION_REVIEW,
    type GetSafetyControlUserStats,
    type SendUserReviewAction,
    GET_USER_PARKING_HISTORY,
} from 'src/redux/safetyControlSystem/safetyControlSystem.types'

type GetSafetyControlSystemData = SagaReturnType<typeof getSafetyControlSystemData>

export function* fetchSafetyControlSystemInfo() {
    const safetyControlSystemData: GetSafetyControlSystemData = yield call(getSafetyControlSystemData)

    if (safetyControlSystemData instanceof Error) {
        yield call(showNotification, 'error', safetyControlSystemData.message)
    } else {
        if (safetyControlSystemData) {
            yield put(setSafetyControlSystemInfo(safetyControlSystemData))
        } else {
            yield put(clearSafetyControlInfo())
            yield put(markNoMoreUsers())
        }
    }
}

type GetUserStats = SagaReturnType<typeof getUserStats>
type GetUserParkingHistory = SagaReturnType<typeof getUserParkingHistory>

export function* fetchSafetyControlSystemData(action: GetSafetyControlUserStats) {
    const { userId, zoneId } = action.payload

    const userStats: GetUserStats = yield call(getUserStats, userId, zoneId)

    if (userStats instanceof Error) {
        yield call(showNotification, 'error', userStats.message)
    } else {
        yield put(setSafetyControlUserStats(userStats))
    }

    const userParkingHistory: GetUserParkingHistory = yield call(getUserParkingHistory, userId, zoneId)

    if (userParkingHistory instanceof Error) {
        yield call(showNotification, 'error', userParkingHistory.message)
    } else {
        yield put(setSafetyControlUserParkingHistory(userParkingHistory))
    }
}

type PersistUserReview = SagaReturnType<typeof persistUserReview>

export function* sendUserReviewAction(action: SendUserReviewAction) {
    const { backlogId, actionReview } = action.payload
    const result: PersistUserReview = yield call(persistUserReview, backlogId, actionReview)

    if (result instanceof Error) {
        if (!result.message.startsWith('409 ErrReviewCompleteConflict')) {
            yield call(showNotification, 'error', result.message)
            return
        }
    }

    yield put(getSafetyControlInfo())
}

function* fetchUserParkingHistory(action: GetSafetyControlUserStats) {
    const { userId, zoneId } = action.payload

    const userParkingHistory: GetUserParkingHistory = yield call(getUserParkingHistory, userId, zoneId)

    if (userParkingHistory instanceof Error) {
        yield call(showNotification, 'error', userParkingHistory.message)
    } else {
        yield put(setSafetyControlUserParkingHistory(userParkingHistory))
    }
}

export default function* watcher() {
    yield takeEvery(SAFETY_CONTROL_SYSTEM_INFO, fetchSafetyControlSystemInfo)
    yield takeEvery(SAFETY_CONTROL_SYSTEM_DATA, fetchSafetyControlSystemData)
    yield takeEvery(SEND_ACTION_REVIEW, sendUserReviewAction)
    yield takeEvery(GET_USER_PARKING_HISTORY, fetchUserParkingHistory)
}
