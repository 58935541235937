import { put, takeEvery, call, select, type SagaReturnType } from 'redux-saga/effects'
import { getRideDuration } from 'src/utils/riders/riders'
import { selectZoneOptions } from 'src/redux/zone'
import { getZoneLabel } from 'src/components/app/utils/zones/zonesUtils'
import { getAllActiveRides } from 'src/api'
import { showNotification } from 'src/components/parts/notifications/notifications'
import { ACTIVE_RIDES_GET_ACTIVE_RIDES } from 'src/redux/activeRides/activeRides.types'
import * as activeRidesActions from 'src/redux/activeRides/activeRides.actions'
import { type Ride } from 'src/api/fm/rentals/rentals.model'
import { type ZoneOption } from 'src/redux/zone/zone.types'

type GetAllActiveRidesRes = SagaReturnType<typeof getAllActiveRides>

function* getRides() {
    try {
        const res: GetAllActiveRidesRes = yield call(getAllActiveRides)
        if (res instanceof Error) {
            throw res
        }
        const zones: ZoneOption[] = yield select(selectZoneOptions)

        const enrichedActiveRides: Ride[] = res.map((r: Ride): Ride => {
            const duration = getRideDuration(r.start_time)
            const first_lock_attempt = r.first_lock_attempt || 0
            return {
                ...r,
                duration,
                first_lock_attempt,
                zone_name: r.zone_id ? getZoneLabel(r.zone_id, zones) : '-',
            }
        })
        yield put(activeRidesActions.setRides(enrichedActiveRides))
    } catch (e) {
        yield call(showNotification, 'error', (e as Error).message)
    }
}

export default function* watcher() {
    yield takeEvery(ACTIVE_RIDES_GET_ACTIVE_RIDES, getRides)
}
