import { call, put, type SagaReturnType, select, takeEvery } from 'redux-saga/effects'
import { searchRider, updateUserRoles as updateUserRolesRequest } from 'src/api'
import { showNotification } from 'src/components/parts/notifications/notifications'
import {
    SEARCH_USER,
    type SearchUser,
    UPDATE_USER_ROLES,
    type UpdateUserRoles,
} from 'src/redux/permissions/permissions.types'
import { searchUserSuccess, searchUserError, updateUserRolesSuccess } from 'src/redux/permissions'
import { selectCurrentUser, setRoles } from 'src/redux/auth'

type SearchRiderRes = SagaReturnType<typeof searchRider>

export function* searchUser(action: SearchUser) {
    const { email } = action.payload
    const riders: SearchRiderRes = yield call(searchRider, email.toLowerCase())
    if (riders instanceof Error) {
        yield call(showNotification, 'error', riders.message)
        yield put(searchUserError())
        return
    }

    const voiAccount = riders.data.data.find((rider: Record<string, any>) => rider.source === 'rides')
    if (!voiAccount) {
        yield call(showNotification, 'error', 'User not found')
        yield put(searchUserError())
        return
    }

    const user = {
        id: voiAccount.userInfo.id,
        email: voiAccount.userInfo.email,
        roles: voiAccount.userInfo.roles,
    }
    yield put(searchUserSuccess(user))
}

type UpdateUserRolesRequestRes = SagaReturnType<typeof updateUserRolesRequest>

export function* updateUserRoles(action: UpdateUserRoles) {
    const { userId, roles } = action.payload
    const res: UpdateUserRolesRequestRes = yield call(updateUserRolesRequest, userId, roles)
    if (res instanceof Error) {
        yield call(showNotification, 'error', res.message)
        return
    }

    yield call(showNotification, 'success', 'Updated user roles')
    yield put(updateUserRolesSuccess())

    const currentUser: ReturnType<typeof selectCurrentUser> = yield select(selectCurrentUser)

    if (userId === currentUser?.userId) {
        yield put(setRoles(roles.global))
    }
}

export default function* watcher() {
    yield takeEvery(SEARCH_USER, searchUser)
    yield takeEvery(UPDATE_USER_ROLES, updateUserRoles)
}
