import { useEffect, Suspense, lazy } from 'react'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from 'src/redux/app/app.hooks'
import { AuthProvider } from 'src/components/app/AuthProvider'
import * as routes from 'src/constants/routes'
import { getAllZones } from 'src/api'
import { setZoneOptions, setAllZones } from 'src/redux/zone'
import { selectIsAuthenticated, selectAuthError } from 'src/redux/auth'
import { GlobalErrorBoundary } from 'src/components/parts/errorBoundaries/GlobalErrorBoundary'
import { ImportErrorBoundary } from 'src/components/parts/errorBoundaries/ImportErrorBoundary'
import Navigation from 'src/components/parts/navigation/Navigation'
import Header from 'src/components/parts/navigation/Header'
import { Login } from 'src/pages/login/Login'
import { H5 } from 'src/ui-kit/headings/Headings'
import { ContentWrapper } from 'src/components/app/ContentWrapper'
import NotFound from 'src/pages/notFound/NotFound'
import { getOrganizations } from 'src/redux/hunter'
import { getPermissionsForRoles } from 'src/redux/permissionsForRoles'
import { fontPrimary } from 'src/ui-kit/fonts/fontFamily'
import { zIndex } from 'src/constants/zIndex'
import { QueryClientProvider } from 'react-query'
import { queryClient } from 'src/api/axios'
import BatteryContextLayout from 'src/pages/batteries/ContextLayout'

export const App = () => {
    const CityRegs = lazy(() =>
        import('src/pages/cityRegs/CityRegs').then(module => ({
            default: module.CityRegs,
        })),
    )
    const Confighub = lazy(() =>
        import('src/pages/confighub/Confighub').then(module => ({
            default: module.Confighub,
        })),
    )
    const DeepIntegrations = lazy(() =>
        import('src/pages/deepIntegrations/DeepIntegrations').then(module => ({
            default: module.DeepIntegrations,
        })),
    )
    const Riders = lazy(() =>
        import('src/pages/riders/Riders').then(module => ({
            default: module.Riders,
        })),
    )
    const AccidentReports = lazy(() =>
        import('src/pages/accidentReports/AccidentReports').then(module => ({
            default: module.AccidentReports,
        })),
    )
    const RideInspector = lazy(() =>
        import('src/pages/rideInspector/RideInspector').then(module => ({
            default: module.RideInspector,
        })),
    )
    const Hunters = lazy(() =>
        import('src/pages/hunters/Hunters').then(module => ({
            default: module.Hunters,
        })),
    )
    const Vehicles = lazy(() =>
        import('src/pages/vehicles/Vehicles').then(module => ({
            default: module.Vehicles,
        })),
    )
    const ParkingPhotoLabelling = lazy(() =>
        import('src/pages/parkingPhotoLabelling/ParkingPhotoLabelling').then(module => ({
            default: module.ParkingPhotoLabelling,
        })),
    )
    const SafetyControlSystem = lazy(() =>
        import('src/pages/safetyControlSystem/SafetyControlSystem').then(module => ({
            default: module.SafetyControlSystem,
        })),
    )
    const Vmap = lazy(() =>
        import('src/pages/vmap/Vmap').then(module => ({
            default: module.Vmap,
        })),
    )
    const Zones = lazy(() =>
        import('src/pages/zones/Zones').then(module => ({
            default: module.Zones,
        })),
    )
    const Countries = lazy(() =>
        import('src/pages/countries/Countries').then(module => ({
            default: module.Countries,
        })),
    )
    const Batteries = lazy(() =>
        import('src/pages/batteries/Batteries').then(module => ({
            default: module.Batteries,
        })),
    )
    const Battery = lazy(() =>
        import('src/pages/battery/Battery').then(module => ({
            default: module.Battery,
        })),
    )
    const BatteryShipments = lazy(() =>
        import('src/pages/batteries/Shipments').then(module => ({
            default: module.Shipments,
        })),
    )
    const BatterySearch = lazy(() =>
        import('src/pages/batterySearch/BatterySearch').then(module => ({
            default: module.BatterySearch,
        })),
    )
    const MaasUsers = lazy(() =>
        import('src/pages/maasUsers/MaasUsers').then(module => ({
            default: module.MaasUsers,
        })),
    )
    const Campaigns = lazy(() =>
        import('src/pages/campaigns/Campaigns').then(module => ({
            default: module.Campaigns,
        })),
    )
    const Overview = lazy(() =>
        import('src/pages/overview/Overview').then(module => ({
            default: module.Overview,
        })),
    )
    const Permissions = lazy(() =>
        import('src/pages/permissions/Permissions').then(module => ({
            default: module.Permissions,
        })),
    )
    const Wallet = lazy(() =>
        import('src/pages/wallet/Wallet').then(module => ({
            default: module.Wallet,
        })),
    )
    const Organizations = lazy(() =>
        import('src/pages/organizations/Organizations').then(module => ({
            default: module.Organizations,
        })),
    )
    const RiderDetails = lazy(() =>
        import('src/pages/riderDetails/RiderDetails').then(module => ({
            default: module.RiderDetails,
        })),
    )
    const ScooterDetails = lazy(() =>
        import('src/pages/scooterDetails/VehicleDetails').then(module => ({
            default: module.VehicleDetails,
        })),
    )
    const HunterDetails = lazy(() =>
        import('src/pages/hunterDetails/HunterDetails').then(module => ({
            default: module.HunterDetails,
        })),
    )
    const CreateOrganization = lazy(() =>
        import('src/pages/createOrganization/CreateOrganization').then(module => ({
            default: module.CreateOrganization,
        })),
    )
    const RepairManagement = lazy(() =>
        import('src/pages/repairManagement/RepairManagement').then(module => ({
            default: module.RepairManagement,
        })),
    )
    const RepairManagementDetails = lazy(() =>
        import('src/pages/repairManagementDetails/RepairManagementDetails').then(module => ({
            default: module.RepairManagementDetails,
        })),
    )
    const RepairManagementVehicles = lazy(() =>
        import('src/pages/repairManagementVehicles/RepairManagementVehicles').then(module => ({
            default: module.RepairManagementVehicles,
        })),
    )
    const VehicleReports = lazy(() =>
        import('src/pages/vehicleReports/VehicleReports').then(module => ({
            default: module.VehicleReports,
        })),
    )
    const PricingPortal = lazy(() =>
        import('src/pages/pricingPortal/PricingPortal').then(module => ({
            default: module.PricingPortal,
        })),
    )

    const VehicleReportDetails = lazy(() =>
        import('src/pages/vehicleReportDetails/VehicleReportDetails').then(module => ({
            default: module.VehicleReportDetails,
        })),
    )

    const VoucherDetails = lazy(() =>
        import('src/pages/vouchersDetails/VoucherDetails').then(module => ({
            default: module.VoucherDetails,
        })),
    )

    const Warehouses = lazy(() =>
        import('src/pages/warehouses/Warehouses').then(module => ({
            default: module.Warehouses,
        })),
    )

    const MaintenanceNeeds = lazy(() =>
        import('src/pages/maintenanceNeeds/MaintenanceNeeds').then(module => ({
            default: module.MaintenanceNeeds,
        })),
    )

    const WorkAreas = lazy(() =>
        import('src/pages/workAreas/WorkAreas').then(module => ({
            default: module.WorkAreas,
        })),
    )

    const isAuthenticated = useAppSelector(selectIsAuthenticated)
    const authError = useAppSelector(selectAuthError)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(getPermissionsForRoles())
            getAllZones()
                .then(res => {
                    if (!(res instanceof Error)) {
                        const zoneOptions = res
                            .map(zone => ({ value: zone.zone_id, label: zone.name, currency: zone.currency }))
                            .sort((optionA, optionB) => optionA.label.localeCompare(optionB.label))
                        dispatch(setZoneOptions(zoneOptions))

                        dispatch(setAllZones(res))
                    }
                    dispatch(getOrganizations()) // TODO: Remove this and let the respective components ask for this data
                })
                .catch(e => console.log(e))
        }
    }, [isAuthenticated, dispatch])

    if (authError) {
        return (
            <NoAccessWarnWrapper data-testid='no-token-warning'>
                <H5>{authError}</H5>
            </NoAccessWarnWrapper>
        )
    }

    return (
        <GlobalErrorBoundary>
            <ImportErrorBoundary>
                <QueryClientProvider client={queryClient}>
                    <BrowserRouter>
                        <AuthProvider>
                            <Routes>
                                <Route path={routes.LOGIN} element={<Login />} />
                                <Route
                                    path='*'
                                    element={
                                        isAuthenticated && (
                                            <MainWrapper>
                                                <Header />
                                                <Navigation />
                                                <Suspense fallback={<ContentWrapper>Loading...</ContentWrapper>}>
                                                    <Routes>
                                                        <Route path={`${routes.CONFIGHUB}/*`} element={<Confighub />} />
                                                        <Route path={`${routes.CITYREGS}/*`} element={<CityRegs />} />
                                                        <Route
                                                            path={`${routes.RIDE_INSPECTOR}/*`}
                                                            element={<RideInspector />}
                                                        />
                                                        <Route
                                                            path={`${routes.DEEP_INTEGRATIONS}/*`}
                                                            element={<DeepIntegrations />}
                                                        />
                                                        <Route
                                                            path={`${routes.PRICING_PORTAL}/*`}
                                                            element={<PricingPortal />}
                                                        />
                                                        <Route
                                                            element={
                                                                <ContentWrapper>
                                                                    <Outlet />
                                                                </ContentWrapper>
                                                            }
                                                        >
                                                            <Route path={routes.OVERVIEW} element={<Overview />} />
                                                            <Route
                                                                path={`${routes.ORG}/:id`}
                                                                element={<Organizations />}
                                                            />
                                                            <Route
                                                                path={routes.CREATE_ORG}
                                                                element={<CreateOrganization />}
                                                            />
                                                            <Route path={routes.HUNTERS} element={<Hunters />} />
                                                            <Route
                                                                path={`${routes.HUNTERS}/:id`}
                                                                element={<HunterDetails />}
                                                            />
                                                            <Route path={routes.VEHICLES} element={<Vehicles />} />
                                                            <Route
                                                                path={routes.PARKING_PHOTO_LABELLING}
                                                                element={<ParkingPhotoLabelling />}
                                                            />
                                                            <Route
                                                                path={routes.SAFETY_CONTROL_SYSTEM}
                                                                element={<SafetyControlSystem />}
                                                            />
                                                            <Route
                                                                path={`${routes.VEHICLES}/:id`}
                                                                element={<ScooterDetails />}
                                                            />
                                                            <Route path={routes.VMAP} element={<Vmap />} />
                                                            <Route path={`${routes.ZONES}/*`} element={<Zones />} />
                                                            <Route path={routes.MAAS} element={<MaasUsers />} />
                                                            <Route path={routes.LEGAL} element={<Countries />} />
                                                            <Route path={routes.CAMPAIGNS} element={<Campaigns />} />
                                                            <Route element={<BatteryContextLayout />}>
                                                                <Route
                                                                    path={routes.BATTERIES}
                                                                    element={<Batteries />}
                                                                />
                                                                <Route
                                                                    path={`${routes.BATTERIES}/:serialNo`}
                                                                    element={<Battery />}
                                                                />
                                                                <Route
                                                                    path={`${routes.BATTERY_SEARCH}`}
                                                                    element={<BatterySearch />}
                                                                />
                                                                <Route
                                                                    path={routes.BATTERY_SHIPMENTS}
                                                                    element={<BatteryShipments />}
                                                                />
                                                            </Route>
                                                            <Route
                                                                path={routes.PERMISSIONS}
                                                                element={<Permissions />}
                                                            />
                                                            <Route path={`${routes.WALLET}/:id`} element={<Wallet />} />
                                                            <Route
                                                                path={`${routes.RIDERS}/:id`}
                                                                element={<RiderDetails />}
                                                            />
                                                            <Route
                                                                path={`${routes.RIDERS}/:id/vouchers`}
                                                                element={<VoucherDetails />}
                                                            />
                                                            <Route path={routes.RIDERS} element={<Riders />} />
                                                            <Route
                                                                path={`${routes.ACCIDENT_REPORTS}/*`}
                                                                element={<AccidentReports />}
                                                            />
                                                            <Route
                                                                path={routes.REPAIR_MANAGEMENT}
                                                                element={<RepairManagement />}
                                                            />
                                                            <Route
                                                                path={`${routes.REPAIR_MANAGEMENT}/:id`}
                                                                element={<RepairManagementDetails />}
                                                            />
                                                            <Route
                                                                path={routes.ADMINISTRATION}
                                                                element={<RepairManagementVehicles />}
                                                            />
                                                            <Route path={routes.WAREHOUSES} element={<Warehouses />} />
                                                            <Route
                                                                path={routes.MAINTENANCE_NEEDS}
                                                                element={<MaintenanceNeeds />}
                                                            />
                                                            <Route
                                                                path={`${routes.VEHICLE_REPORTS}/:id`}
                                                                element={<VehicleReportDetails />}
                                                            />
                                                            <Route
                                                                path={routes.VEHICLE_REPORTS}
                                                                element={<VehicleReports />}
                                                            />
                                                            <Route path={routes.WORK_AREAS} element={<WorkAreas />} />
                                                            <Route path='*' element={<NotFound />} />
                                                        </Route>
                                                    </Routes>
                                                </Suspense>
                                                <ToastContainer
                                                    autoClose={5000}
                                                    theme='colored'
                                                    position='bottom-right'
                                                    icon={false}
                                                />
                                            </MainWrapper>
                                        )
                                    }
                                />
                            </Routes>
                        </AuthProvider>
                    </BrowserRouter>
                </QueryClientProvider>
            </ImportErrorBoundary>
        </GlobalErrorBoundary>
    )
}

const NoAccessWarnWrapper = styled.div`
    background-color: #ed6a5a;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${zIndex.priority};
    padding: 40px;
`

const MainWrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    ${fontPrimary}
    width: 100vw;
    height: 100vh;

    /* Add padding-top to not cover header */
    padding-top: 70px;
`
