import { call, put, type SagaReturnType, select, takeEvery } from 'redux-saga/effects'
import {
    createHunterOrganizationAllowedArea,
    createNewHunter,
    createOrganization,
    deleteHunterOrganizationAllowedArea,
    deleteOrganization,
    editHunterOrganizationAllowedArea,
    editOrganization,
    getHunterOrganizationAllowedAreas,
    getOrganization,
    getOrganizationHistoryTasks,
} from 'src/api'
import { showNotification } from 'src/components/parts/notifications/notifications'
import {
    createOrganizationSucceeded,
    deleteOrganizationSucceeded,
    editOrganizationSucceeded,
    getOrganizationHistoryTasks as getOrganizationHistoryTasksAction,
    incrementOrganizationHistoryPageNum,
    setCurrentOrganization,
    setEndOfPagination,
    setOrganizationHistoryTasks,
    selectCurrentOrganizationState,
    selectOrganizationHistoryTasks,
    setIsFetchingOrganizationHistoryTasks,
    setOrganizationAllowedAreas,
    getOrganizationAllowedAreas,
} from 'src/redux/organization'
import { appendTaskIdsWithParkingPhoto, setIsFetchingMoreTasksDone } from 'src/redux/hunterParkingPhotos'
import {
    CREATE_HUNTER_IN_ORGANIZATION,
    CREATE_ORGANIZATION,
    DELETE_ORGANIZATION,
    EDIT_ORGANIZATION,
    GET_NEXT_HISTORY_PAGE,
    GET_ORGANIZATION,
    GET_ORGANIZATION_HISTORY_TASKS,
    INCREMENT_ORGANIZATION_HISTORY_PAGE_NUM,
    SET_ORGANIZATION_HISTORY_FILTER,
    type Organization,
    type CreateOrganization,
    type EditOrganization,
    type CreateHunterInOrganization,
    type DeleteOrganization,
    type GetOrganization,
    GET_ORGANIZATION_ALLOWED_AREAS,
    type GetOrganizationAllowedAreas,
    CREATE_ORGANIZATION_ALLOWED_AREA,
    type CreateOrganizationAllowedArea,
    type EditOrganizationAllowedArea,
    EDIT_ORGANIZATION_ALLOWED_AREA,
    DELETE_ORGANIZATION_ALLOWED_AREA,
    type DeleteOrganizationAllowedArea,
} from 'src/redux/organization/organization.types'
import { fetchVehicleIds } from 'src/redux/vehicleIds/vehicleIds.actions'
import { type Task } from 'src/api/fm/tasks/tasks.model'
import * as routes from 'src/constants/routes'

type CreateOrganizationRes = SagaReturnType<typeof createOrganization>

export function* createOrg(action: CreateOrganization) {
    try {
        const { organization, pushToRoute } = action.payload
        const res: CreateOrganizationRes = yield call(createOrganization, organization)
        if (res instanceof Error) {
            throw res
        }
        yield put(createOrganizationSucceeded(res))
        pushToRoute(res.id)
    } catch (e) {
        yield call(showNotification, 'error', (e as Error).message)
    }
}

export function* editOrg(action: EditOrganization) {
    try {
        const res: Organization = yield call(editOrganization, action.payload)
        if (res instanceof Error) {
            throw res
        }
        yield put(setCurrentOrganization(res))
        yield call(showNotification, 'success', 'Successfully updated organization')
        yield put(editOrganizationSucceeded(res))
    } catch (e) {
        yield call(showNotification, 'error', (e as Error).message)
    }
}

type CreateNewHunterRes = SagaReturnType<typeof createNewHunter>

export function* createHunterInOrg(action: CreateHunterInOrganization) {
    try {
        const res: CreateNewHunterRes = yield call(createNewHunter, action.payload)
        if (res instanceof Error) {
            throw res
        }
        yield call(
            showNotification,
            'success',
            'Successfully created hunter account. An email to set up the password has been sent to the user.',
        )
        const org: Organization = yield call(getOrganization, action.payload.organizationId)
        if (org instanceof Error) {
            throw org
        }
        yield put(setCurrentOrganization(org))
    } catch (e: any) {
        if (e.response && e.response.data && e.response.data.errors && e.response.data.errors.length) {
            switch (e.response.data.errors[0].code) {
                case 'ErrHunterAlreadyExist':
                    yield call(showNotification, 'error', 'The hunter already exists.')
                    break
                case 'ErrInvalidInputEmail':
                    yield call(showNotification, 'error', 'Invalid applicant email.')
                    break
                case 'ErrCouldNotSendNewPassword':
                    yield call(
                        showNotification,
                        'error',
                        'Failed to send password to the hunter. Please reset password for the hunter manually.',
                    )
                    break
                default:
                    yield call(showNotification, 'error', e.message)
            }
        } else {
            yield call(showNotification, 'error', e.message)
        }
    }
}

type DeleteOrganizationRes = SagaReturnType<typeof deleteOrganization>

export function* deleteOrg(action: DeleteOrganization) {
    const { orgId, navigateFn } = action.payload

    const res: DeleteOrganizationRes = yield call(deleteOrganization, orgId)
    if (res instanceof Error) {
        yield call(showNotification, 'error', res.message)
    } else {
        yield put(setCurrentOrganization(null))
        // @ts-expect-error  https://github.com/redux-saga/redux-saga/issues/1943
        yield call(navigateFn, routes.HUNTERS)
        yield call(showNotification, 'success', 'Successfully deleted organization')
        yield put(deleteOrganizationSucceeded(orgId))
    }
}

type GetOrganizationRes = SagaReturnType<typeof getOrganization>

export function* getOrg(action: GetOrganization) {
    try {
        const res: GetOrganizationRes = yield call(getOrganization, action.payload)
        if (res instanceof Error) {
            throw res
        }
        yield put(setCurrentOrganization(res))
        yield put(getOrganizationHistoryTasksAction())
    } catch (e) {
        yield call(showNotification, 'error', (e as Error).message)
    }
}

export function* getNextPage() {
    try {
        const { endOfPagination } = yield select(selectCurrentOrganizationState)
        if (!endOfPagination) {
            yield put(incrementOrganizationHistoryPageNum())
        }
    } catch (e) {
        yield call(showNotification, 'error', (e as Error).message)
    }
}

type GetOrganizationHistoryTasksRes = SagaReturnType<typeof getOrganizationHistoryTasks>

export function* getOrgHistoryTasks() {
    try {
        const { endOfPagination, pageNum, organization, filters } = yield select(selectCurrentOrganizationState)
        const orgId = organization && organization.id
        const email = filters && filters.email

        if (endOfPagination) {
            return
        }

        yield put(setIsFetchingOrganizationHistoryTasks(true))

        const res: GetOrganizationHistoryTasksRes = yield call(getOrganizationHistoryTasks, orgId, pageNum, email)
        if (res instanceof Error) {
            throw res
        }

        if (!res || !res.length) {
            yield put(setEndOfPagination())
        } else {
            const vehicleIds = res.map((task: Task) => task.vehicleId)
            yield put(fetchVehicleIds(vehicleIds))

            const prevTasks: Task[] = yield select(selectOrganizationHistoryTasks)
            const newTasks = prevTasks.concat(res)
            const newTaskIdsWithPhoto = res.filter((t: Task) => t.hasPhoto).map((t: Task) => t.id)
            yield put(setOrganizationHistoryTasks(newTasks))
            yield put(appendTaskIdsWithParkingPhoto(newTaskIdsWithPhoto, 'organization'))
        }
    } catch (e) {
        yield call(showNotification, 'error', (e as Error).message)
    } finally {
        yield put(setIsFetchingMoreTasksDone())
        yield put(setIsFetchingOrganizationHistoryTasks(false))
    }
}

type GetOrganizationAllowedAreasRes = SagaReturnType<typeof getHunterOrganizationAllowedAreas>

export function* getOrgAllowedAreasSaga(action: GetOrganizationAllowedAreas) {
    try {
        const orgId = action.payload

        const res: GetOrganizationAllowedAreasRes = yield call(getHunterOrganizationAllowedAreas, orgId)

        if (res instanceof Error) {
            throw res
        }

        yield put(setOrganizationAllowedAreas(res))
    } catch (e) {
        yield call(showNotification, 'error', (e as Error).message)
    }
}

type CreateOrganizationAllowedAreaRes = SagaReturnType<typeof createHunterOrganizationAllowedArea>

export function* createOrgAllowedAreaSaga(action: CreateOrganizationAllowedArea) {
    try {
        const allowedArea = action.payload

        const res: CreateOrganizationAllowedAreaRes = yield call(createHunterOrganizationAllowedArea, allowedArea)

        if (res instanceof Error) {
            throw res
        }

        yield put(getOrganizationAllowedAreas(allowedArea.organizationId))
        yield call(showNotification, 'success', 'Successfully created organization allowed area')
    } catch (e) {
        yield call(showNotification, 'error', (e as Error).message)
    }
}

type EditOrganizationAllowedAreaRes = SagaReturnType<typeof editHunterOrganizationAllowedArea>

export function* editOrgAllowedAreaSaga(action: EditOrganizationAllowedArea) {
    try {
        const allowedArea = action.payload

        const res: EditOrganizationAllowedAreaRes = yield call(editHunterOrganizationAllowedArea, allowedArea)

        if (res instanceof Error) {
            throw res
        }

        yield put(getOrganizationAllowedAreas(allowedArea.organizationId))
        yield call(showNotification, 'success', 'Successfully updated organization allowed area')
    } catch (e) {
        yield call(showNotification, 'error', (e as Error).message)
    }
}

type DeleteOrganizationAllowedAreaRes = SagaReturnType<typeof deleteHunterOrganizationAllowedArea>

export function* deleteOrgAllowedAreaSaga(action: DeleteOrganizationAllowedArea) {
    try {
        const allowedArea = action.payload

        const res: DeleteOrganizationAllowedAreaRes = yield call(deleteHunterOrganizationAllowedArea, allowedArea)

        if (res instanceof Error) {
            throw res
        }

        yield put(getOrganizationAllowedAreas(allowedArea.organizationId))
        yield call(showNotification, 'success', 'Successfully deleted organization allowed area')
    } catch (e) {
        yield call(showNotification, 'error', (e as Error).message)
    }
}

export default function* watcher() {
    yield takeEvery(EDIT_ORGANIZATION, editOrg)
    yield takeEvery(CREATE_HUNTER_IN_ORGANIZATION, createHunterInOrg)
    yield takeEvery(DELETE_ORGANIZATION, deleteOrg)
    yield takeEvery(GET_ORGANIZATION, getOrg)
    yield takeEvery(GET_ORGANIZATION_HISTORY_TASKS, getOrgHistoryTasks)
    yield takeEvery(CREATE_ORGANIZATION, createOrg)
    yield takeEvery(SET_ORGANIZATION_HISTORY_FILTER, getOrgHistoryTasks)
    yield takeEvery(INCREMENT_ORGANIZATION_HISTORY_PAGE_NUM, getOrgHistoryTasks)
    yield takeEvery(GET_NEXT_HISTORY_PAGE, getNextPage)
    yield takeEvery(GET_ORGANIZATION_ALLOWED_AREAS, getOrgAllowedAreasSaga)
    yield takeEvery(CREATE_ORGANIZATION_ALLOWED_AREA, createOrgAllowedAreaSaga)
    yield takeEvery(EDIT_ORGANIZATION_ALLOWED_AREA, editOrgAllowedAreaSaga)
    yield takeEvery(DELETE_ORGANIZATION_ALLOWED_AREA, deleteOrgAllowedAreaSaga)
}
